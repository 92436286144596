import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  select: {
    height: 40,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  },
}));

const QueueSelect = ({ selectedQueueIds, onChange }) => {
  const classes = useStyles();
  const [queues, setQueues] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth margin="dense" variant="outlined" size="small">
        <InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>
        <Select
          multiple
          labelWidth={60}
          value={selectedQueueIds}
          onChange={handleChange}
          size="small"
          className={classes.select}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={selected => {
            const selectedQueues = selected.slice(0, 2);
            const remainingCount = selected.length - selectedQueues.length;

            return (
              <div className={classes.chips}>
                {selectedQueues.map(id => {
                  const queue = queues.find(q => q.id === id);
                  return queue ? (
                    <Chip
                      key={id}
                      style={{ backgroundColor: queue.color }}
                      variant="outlined"
                      label={queue.name}
                      size="small"
                      className={classes.chip}
                    />
                  ) : null;
                })}
                {remainingCount > 0 && (
                  <Chip
                    key="more"
                    variant="outlined"
                    label={`+${remainingCount} Filas`}
                    size="small"
                    className={classes.chip}
                  />
                )}
              </div>
            );
          }}
        >
          {queues.map(queue => (
            <MenuItem key={queue.id} value={queue.id}>
              {queue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default QueueSelect;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
})); 

const QueueSelectCustom = ({ selectedQueueIds, onChange}) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);

	useEffect(() => {
		const fetchQueues = async () => {
		  try {
			const { data } = await api.get("/queue/user");
			setQueues(data);
		  } catch (err) {
			toastError("Erro ao carregar as filas. Por favor, tente novamente.");
		  } 
		};
	
		fetchQueues();
	  }, []);

	const handleChange = e => {
		onChange(e.target.value);
		
	};

	return (
		<div style={{ marginTop: 6 }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					labelWidth={60}
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
						  {selected?.length > 0 &&
							selected.map(id => {
							  if (id === "0") {
								return (
								  <Chip
									key={id}
									size="small"
									label={i18n.t("queueSelect.withoutQueue")}
									className={classes.chip}
								  />
								);
							  }
					  
							  const queue = queues.find(q => q.id === id);
					  
						
							  if (!queue) {
								return null;
							  }
					  
					
							  return (
								<Chip
								  key={id}
								  style={{ backgroundColor: queue.color }}
								  variant="outlined"
								  label={queue.name}
								  className={classes.chip}
								/>
							  );
							})}
						</div>
					  )}
					  
				>
					<MenuItem value="0">
						{i18n.t("queueSelect.withoutQueue")}
					</MenuItem>
					{queues.map(queue => (
						<MenuItem key={queue.id} value={queue.id}>
							{queue.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueSelectCustom;

import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { isNil } from "lodash";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";

import { Autorenew, FileCopy } from "@material-ui/icons";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import useCompanySettings from "../../hooks/useSettings/companySettings";
import SchedulesForm from "../SchedulesForm";
import usePlans from "../../hooks/usePlans";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 10,
  },
  tabPanel: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnWrapper: {
    position: "relative",
  },
  select: {
    "& .MuiSelect-outlined": {
      borderRadius: 10,
    },
  },
  container: {
    padding: theme.spacing(1),
  },
  multFieldLine: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  importMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    border: "solid grey 2px",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  tokenRefresh: {
    minWidth: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1),
  },
}));

function TabPanelContainer(props) {
  const { children, value, name } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`whatsapp-tabpanel-${name}`}
      aria-labelledby={`whatsapp-tab-${name}`}
      className={classes.tabPanel}
    >
      {value === name && <Box>{children}</Box>}
    </div>
  );
}

const SessionSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const [autoToken, setAutoToken] = useState("");

  const inputFileRef = useRef(null);

  const [attachment, setAttachment] = useState(null);
  const [attachmentName, setAttachmentName] = useState("");

  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    maxUseBotQueues: 3,
    provider: "beta",
    expiresTicket: 0,
    allowGroup: false,
    enableImportMessage: false,
    groupAsTicket: "disabled",
    timeUseBotQueues: "0",
    timeSendQueue: "0",
    sendIdQueue: 0,
    expiresTicketNPS: "0",
    expiresInactiveMessage: "",
    timeInactiveMessage: "",
    inactiveMessage: "",
    maxUseBotQueuesNPS: 3,
    whenExpiresTicket: 0,
    timeCreateNewTicket: 0,
    greetingMediaAttachment: "",
    importRecentMessages: "",
    importOldMessages: "",
    importOldMessagesGroups: "",
    integrationId: "",
    collectiveVacationEnd: "",
    collectiveVacationStart: "",
    collectiveVacationMessage: "",
    queueIdImportMessages: null,
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [queues, setQueues] = useState([]);
  const [tab, setTab] = useState("general");
  const [enableImportMessage, setEnableImportMessage] = useState(false);
  const [importOldMessagesGroups, setImportOldMessagesGroups] = useState(false);
  const [closedTicketsPostImported, setClosedTicketsPostImported] = useState(false);
  const [importOldMessages, setImportOldMessages] = useState(moment().add(-1, "days").format("YYYY-MM-DDTHH:mm"));
  const [importRecentMessages, setImportRecentMessages] = useState(moment().add(-1, "minutes").format("YYYY-MM-DDTHH:mm"));
  const [copied, setCopied] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const [NPSEnabled, setNPSEnabled] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const { user } = useContext(AuthContext);

  const [schedules, setSchedules] = useState([
    { weekday: i18n.t("queueModal.serviceHours.monday"), weekdayEn: "monday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
    { weekday: i18n.t("queueModal.serviceHours.tuesday"), weekdayEn: "tuesday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
    { weekday: i18n.t("queueModal.serviceHours.wednesday"), weekdayEn: "wednesday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
    { weekday: i18n.t("queueModal.serviceHours.thursday"), weekdayEn: "thursday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
    { weekday: i18n.t("queueModal.serviceHours.friday"), weekdayEn: "friday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
    { weekday: "Sábado", weekdayEn: "saturday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
    { weekday: "Domingo", weekdayEn: "sunday", startTimeA: "08:00", endTimeA: "12:00", startTimeB: "13:00", endTimeB: "18:00" },
  ]);

  const { get: getSetting } = useCompanySettings();
  const { getPlanCompany } = usePlans();

  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompts, setPrompts] = useState([]);

  useEffect(() => {
    if (!whatsAppId && !whatsApp.token) {
      setAutoToken(generateRandomCode(30));
    } else if (whatsAppId && !whatsApp.token) {
      setAutoToken(generateRandomCode(30));
    } else {
      setAutoToken(whatsApp.token);
    }
  }, [whatsAppId, whatsApp.token]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        setPrompts(data.prompts);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [whatsAppId]);

  useEffect(() => {
    const fetchData = async () => {
      const settingSchedules = await getSetting({
        column: "scheduleType",
      });
      setSchedulesEnabled(settingSchedules.scheduleType === "connection");
      const settingNPS = await getSetting({
        column: "userRating",
      });
      setNPSEnabled(settingNPS.userRating === "enabled");
    };
    fetchData();
  }, []);

  const handleEnableImportMessage = async (e) => {
    setEnableImportMessage(e.target.checked);
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);
        setAttachmentName(data.greetingMediaAttachment);
        setAutoToken(data.token);
        data.promptId ? setSelectedPrompt(data.promptId) : setSelectedPrompt(null);
        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
        setSchedules(data.schedules);
        if (!isNil(data?.importOldMessages)) {
          setEnableImportMessage(true);
          setImportOldMessages(data?.importOldMessages);
          setImportRecentMessages(data?.importRecentMessages);
          setClosedTicketsPostImported(data?.closedTicketsPostImported);
          setImportOldMessagesGroups(data?.importOldMessagesGroups);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queueIntegration");

        setIntegrations(data.queueIntegrations);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChangeQueue = (e) => {
    setSelectedQueueIds(e);
    setSelectedPrompt(null);
  };

  const handleChangePrompt = (e) => {
    setSelectedPrompt(e.target.value);
    setSelectedQueueIds([]);
  };

  const handleSaveWhatsApp = async (values) => {
    if (!whatsAppId) setAutoToken(generateRandomCode(30));

    if (NPSEnabled) {
      if (isNil(values.ratingMessage)) {
        toastError(i18n.t("whatsappModal.errorRatingMessage"));
        return;
      }

      if (values.expiresTicketNPS === "0" && values.expiresTicketNPS === "" && values.expiresTicketNPS === 0) {
        toastError(i18n.t("whatsappModal.errorExpiresNPS"));
        return;
      }
    }

    if (values.timeSendQueue === "") values.timeSendQueue = "0";

    if (
      (values.sendIdQueue === 0 || values.sendIdQueue === "" || isNil(values.sendIdQueue)) &&
      (values.timeSendQueue !== 0 && values.timeSendQueue !== "0")
    ) {
      toastError(i18n.t("whatsappModal.errorSendQueue"));
      return;
    }

    const whatsappData = {
      ...values,
      queueIds: selectedQueueIds,
      importOldMessages: enableImportMessage ? importOldMessages : null,
      importRecentMessages: enableImportMessage ? importRecentMessages : null,
      importOldMessagesGroups: importOldMessagesGroups ? importOldMessagesGroups : null,
      closedTicketsPostImported: closedTicketsPostImported ? closedTicketsPostImported : null,
      token: autoToken ? autoToken : null,
      schedules,
      promptId: selectedPrompt ? selectedPrompt : null,
    };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    try {
      if (whatsAppId) {
        if (whatsAppId && enableImportMessage && whatsApp?.status === "CONNECTED") {
          try {
            setWhatsApp({ ...whatsApp, status: "qrcode" });
            await api.delete(`/whatsappsession/${whatsApp.id}`);
          } catch (err) {
            toastError(err);
          }
        }

        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/whatsapp/${whatsAppId}/media-upload`, formData);
        }
        if (!attachmentName && whatsApp.greetingMediaAttachment !== null) {
          await api.delete(`/whatsapp/${whatsAppId}/media-upload`);
        }
      } else {
        const { data } = await api.post("/whatsapp", whatsappData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/whatsapp/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("whatsappModal.success"));

      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  function generateRandomCode(length) {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyvz0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      code += charset.charAt(randomIndex);
    }
    return code;
  }

  const handleRefreshToken = () => {
    setAutoToken(generateRandomCode(30));
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(autoToken);
    setCopied(true);
  };

  const handleSaveSchedules = async (values) => {
    toast.success("Clique em salvar para registrar as alterações");
    setSchedules(values);
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
    setEnableImportMessage(false);
    setAttachment(null);
    setAttachmentName("");
    setCopied(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleFileUpload = () => {
    const file = inputFileRef.current.files[0];
    setAttachment(file);
    setAttachmentName(file.name);
    inputFileRef.current.value = null;
  };

  const handleDeleteFile = () => {
    setAttachment(null);
    setAttachmentName(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="form-dialog-title">
        {whatsAppId ? i18n.t("whatsappModal.title.edit") : i18n.t("whatsappModal.title.add")}
      </DialogTitle>
      <Formik
        initialValues={whatsApp}
        enableReinitialize={true}
        validationSchema={SessionSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveWhatsApp(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, touched, errors, isSubmitting }) => (
          <Form>
            <Box>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                variant="scrollable"
                onChange={handleTabChange}
              >
                <Tab label={i18n.t("whatsappModal.tabs.general")} value={"general"}  />
                <Tab label={i18n.t("whatsappModal.tabs.integrations")} value={"integrations"} />
                <Tab label={i18n.t("whatsappModal.tabs.messages")} value={"messages"} />
                <Tab label="Chatbot" value={"chatbot"} />
                <Tab label={i18n.t("whatsappModal.tabs.assessments")} value={"nps"} />
                {schedulesEnabled && <Tab label={i18n.t("whatsappModal.tabs.schedules")} value={"schedules"} />}
              </Tabs>
            </Box>
            <Box>
              <TabPanelContainer value={tab} name={"general"}>
                <DialogContent className={classes.container}>
                  {attachmentName && (
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<DeleteOutlineIcon />}
                        onClick={handleDeleteFile}
                        style={{ borderRadius: 10 }}
                      >
                        {attachmentName}
                      </Button>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="column" mb={2}>
                    <input
                      type="file"
                      accept="video/*,image/*"
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => inputFileRef.current.click()}
                      style={{ borderRadius: 10 }}
                    >
                      {i18n.t("userModal.buttons.addImage")}
                    </Button>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.name")}
                        autoFocus
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        size="small"
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} container alignItems="center">
                      <FormControlLabel
                        control={
                          <Field as={Switch} color="primary" name="isDefault" checked={values.isDefault} />
                        }
                        label={i18n.t("whatsappModal.form.default")}
                      />
                      <FormControlLabel
                        control={
                          <Field as={Switch} color="primary" name="allowGroup" checked={values.allowGroup} />
                        }
                        label={i18n.t("whatsappModal.form.group")}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        size="small"
                        className={classes.formControl}
                      >
                        <InputLabel id="groupAsTicket-selection-label">
                          {i18n.t("whatsappModal.form.groupAsTicket")}
                        </InputLabel>
                        <Field
                          as={Select}
                          name="groupAsTicket"
                          id="groupAsTicket"
                          value={values.groupAsTicket || ""}
                          required
                          label={i18n.t("whatsappModal.form.groupAsTicket")}
                          placeholder={i18n.t("whatsappModal.form.groupAsTicket")}
                          labelId="groupAsTicket-selection-label"
                          className={classes.select}
                        >
                          <MenuItem value={"disabled"}>{i18n.t("whatsappModal.menuItem.disabled")}</MenuItem>
                          <MenuItem value={"enabled"}>{i18n.t("whatsappModal.menuItem.enabled")}</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box className={classes.importMessage}>
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
                      <FormControlLabel
                        label={i18n.t("whatsappModal.form.importOldMessagesEnable")}
                        labelPlacement="end"
                        control={
                          <Switch
                            size="medium"
                            checked={enableImportMessage}
                            onChange={handleEnableImportMessage}
                            name="importOldMessagesEnable"
                            color="primary"
                          />
                        }
                      />

                      {enableImportMessage && (
                        <>
                          <FormControlLabel
                            label={i18n.t("whatsappModal.form.importOldMessagesGroups")}
                            labelPlacement="end"
                            control={
                              <Switch
                                size="medium"
                                checked={importOldMessagesGroups}
                                onChange={(e) => setImportOldMessagesGroups(e.target.checked)}
                                name="importOldMessagesGroups"
                                color="primary"
                              />
                            }
                          />

                          <FormControlLabel
                            label={i18n.t("whatsappModal.form.closedTicketsPostImported")}
                            labelPlacement="end"
                            control={
                              <Switch
                                size="medium"
                                checked={closedTicketsPostImported}
                                onChange={(e) => setClosedTicketsPostImported(e.target.checked)}
                                name="closedTicketsPostImported"
                                color="primary"
                              />
                            }
                          />
                        </>
                      )}
                    </Box>

                    {enableImportMessage && (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={4}>
                          <Field
                            fullWidth
                            as={TextField}
                            label={i18n.t("whatsappModal.form.importOldMessages")}
                            type="datetime-local"
                            name="importOldMessages"
                            inputProps={{
                              max: moment().add(0, "minutes").format("YYYY-MM-DDTHH:mm"),
                              min: moment().add(-2, "years").format("YYYY-MM-DDTHH:mm"),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={touched.importOldMessages && Boolean(errors.importOldMessages)}
                            helperText={touched.importOldMessages && errors.importOldMessages}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={moment(importOldMessages).format("YYYY-MM-DDTHH:mm")}
                            onChange={(e) => {
                              setImportOldMessages(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Field
                            fullWidth
                            as={TextField}
                            label={i18n.t("whatsappModal.form.importRecentMessages")}
                            type="datetime-local"
                            name="importRecentMessages"
                            inputProps={{
                              max: moment().add(0, "minutes").format("YYYY-MM-DDTHH:mm"),
                              min: moment(importOldMessages).format("YYYY-MM-DDTHH:mm"),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={touched.importRecentMessages && Boolean(errors.importRecentMessages)}
                            helperText={touched.importRecentMessages && errors.importRecentMessages}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={moment(importRecentMessages).format("YYYY-MM-DDTHH:mm")}
                            onChange={(e) => {
                              setImportRecentMessages(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ marginTop: "-4px" }}>
                          <FormControl
                            variant="outlined"
                            margin="dense"
                            className={classes.formControl}
                            fullWidth
                            size="small"
                          >
                            <InputLabel id="queueIdImportMessages-selection-label">
                              {i18n.t("whatsappModal.form.queueIdImportMessages")}
                            </InputLabel>
                            <Field
                              as={Select}
                              name="queueIdImportMessages"
                              id="queueIdImportMessages"
                              value={values.queueIdImportMessages || ""}
                              required={enableImportMessage}
                              label={i18n.t("whatsappModal.form.queueIdImportMessages")}
                              placeholder={i18n.t("whatsappModal.form.queueIdImportMessages")}
                              labelId="queueIdImportMessages-selection-label"
                              className={classes.select}
                            >
                              <MenuItem value={0}>&nbsp;</MenuItem>
                              {queues.map((queue) => (
                                <MenuItem key={queue.id} value={queue.id}>
                                  {queue.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  {enableImportMessage && (
                    <Typography variant="body2" color="error">
                      {i18n.t("whatsappModal.form.importAlert")}
                    </Typography>
                  )}

                  <Box display="flex" alignItems="center">
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.token")}
                        type="text"
                        fullWidth
                        value={autoToken}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        disabled
                        className={classes.textField}
                      />
                    </Grid>
                    <IconButton onClick={handleRefreshToken} className={classes.tokenRefresh}>
                      <Autorenew style={{ color: "green" }} />
                    </IconButton>
                    <IconButton onClick={handleCopyToken} className={classes.tokenRefresh}>
                      <FileCopy style={{ color: copied ? "blue" : "inherit" }} />
                    </IconButton>
                  </Box>

                  <Box>
                    <Typography variant="h6">{i18n.t("whatsappModal.form.queueRedirection")}</Typography>
                    <Typography variant="body2">{i18n.t("whatsappModal.form.queueRedirectionDesc")}</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl}
                          fullWidth
                          size="small"
                        >
                          <InputLabel id="sendIdQueue-selection-label">
                            {i18n.t("whatsappModal.form.sendIdQueue")}
                          </InputLabel>
                          <Field
                            as={Select}
                            name="sendIdQueue"
                            id="sendIdQueue"
                            value={values.sendIdQueue || "0"}
                            required={values.timeSendQueue > 0}
                            label={i18n.t("whatsappModal.form.sendIdQueue")}
                            placeholder={i18n.t("whatsappModal.form.sendIdQueue")}
                            labelId="sendIdQueue-selection-label"
                            className={classes.select}
                          >
                            <MenuItem value={0}>&nbsp;</MenuItem>
                            {queues.map((queue) => (
                              <MenuItem key={queue.id} value={queue.id}>
                                {queue.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          as={TextField}
                          label={i18n.t("whatsappModal.form.timeSendQueue")}
                          fullWidth
                          name="timeSendQueue"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          error={touched.timeSendQueue && Boolean(errors.timeSendQueue)}
                          helperText={touched.timeSendQueue && errors.timeSendQueue}
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
              </TabPanelContainer>

              {/* Integrations Tab */}
              <TabPanelContainer value={tab} name={"integrations"}>
                <DialogContent className={classes.container}>
                  {/* Queue Selection */}
                  <QueueSelect
                    selectedQueueIds={selectedQueueIds}
                    onChange={(selectedIds) => handleChangeQueue(selectedIds)}
                  />
                  {showIntegrations && (
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      className={classes.formControl}
                      fullWidth
                      size="small"
                    >
                      <InputLabel id="integrationId-selection-label">
                        {i18n.t("queueModal.form.integrationId")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("queueModal.form.integrationId")}
                        name="integrationId"
                        id="integrationId"
                        value={values.integrationId || ""}
                        variant="outlined"
                        margin="dense"
                        placeholder={i18n.t("queueModal.form.integrationId")}
                        labelId="integrationId-selection-label"
                        className={classes.select}
                      >
                        <MenuItem value={null}>{"Desabilitado"}</MenuItem>
                        {integrations.map((integration) => (
                          <MenuItem key={integration.id} value={integration.id}>
                            {integration.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  )}
                  {showOpenAi && (
                    <FormControl
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.formControl}
                    >
                      <InputLabel>{i18n.t("whatsappModal.form.prompt")}</InputLabel>
                      <Select
                        labelId="dialog-select-prompt-label"
                        id="dialog-select-prompt"
                        name="promptId"
                        value={selectedPrompt || ""}
                        onChange={handleChangePrompt}
                        label={i18n.t("whatsappModal.form.prompt")}
                        fullWidth
                        className={classes.select}
                      >
                        {prompts.map((prompt) => (
                          <MenuItem key={prompt.id} value={prompt.id}>
                            {prompt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </DialogContent>
              </TabPanelContainer>

              {/* Messages Tab */}
              <TabPanelContainer value={tab} name={"messages"}>
                <DialogContent className={classes.container}>
                  <Grid container>
                    {/* Greeting Message */}
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.greetingMessage")}
                        multiline
                        rows={3}
                        fullWidth
                        name="greetingMessage"
                        error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                        helperText={touched.greetingMessage && errors.greetingMessage}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Completion Message */}
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.complationMessage")}
                        multiline
                        rows={3}
                        fullWidth
                        name="complationMessage"
                        error={touched.complationMessage && Boolean(errors.complationMessage)}
                        helperText={touched.complationMessage && errors.complationMessage}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Out of Hours Message */}
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.outOfHoursMessage")}
                        multiline
                        rows={3}
                        fullWidth
                        name="outOfHoursMessage"
                        error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
                        helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Collective Vacation Message */}
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.collectiveVacationMessage")}
                        multiline
                        rows={3}
                        fullWidth
                        name="collectiveVacationMessage"
                        error={touched.collectiveVacationMessage && Boolean(errors.collectiveVacationMessage)}
                        helperText={touched.collectiveVacationMessage && errors.collectiveVacationMessage}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Collective Vacation Dates */}
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          as={TextField}
                          label={i18n.t("whatsappModal.form.collectiveVacationStart")}
                          type="date"
                          name="collectiveVacationStart"
                          required={values.collectiveVacationMessage?.length > 0}
                          inputProps={{
                            min: moment().add(-10, "days").format("YYYY-MM-DD"),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={touched.collectiveVacationStart && Boolean(errors.collectiveVacationStart)}
                          helperText={touched.collectiveVacationStart && errors.collectiveVacationStart}
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          as={TextField}
                          label={i18n.t("whatsappModal.form.collectiveVacationEnd")}
                          type="date"
                          name="collectiveVacationEnd"
                          required={values.collectiveVacationMessage?.length > 0}
                          inputProps={{
                            min: moment().add(-10, "days").format("YYYY-MM-DD"),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={touched.collectiveVacationEnd && Boolean(errors.collectiveVacationEnd)}
                          helperText={touched.collectiveVacationEnd && errors.collectiveVacationEnd}
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </TabPanelContainer>

              {/* Chatbot Tab */}
              <TabPanelContainer value={tab} name={"chatbot"}>
                <DialogContent className={classes.container}>
                  <Grid container spacing={1}>
                    {/* Time to Create New Ticket */}
                    <Grid item xs={12} md={4}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.timeCreateNewTicket")}
                        fullWidth
                        name="timeCreateNewTicket"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={touched.timeCreateNewTicket && Boolean(errors.timeCreateNewTicket)}
                        helperText={touched.timeCreateNewTicket && errors.timeCreateNewTicket}
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Max Uses of Bot Queues */}
                    <Grid item xs={12} md={4}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.maxUseBotQueues")}
                        fullWidth
                        name="maxUseBotQueues"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={touched.maxUseBotQueues && Boolean(errors.maxUseBotQueues)}
                        helperText={touched.maxUseBotQueues && errors.maxUseBotQueues}
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Time to Use Bot Queues */}
                    <Grid item xs={12} md={4}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.timeUseBotQueues")}
                        fullWidth
                        name="timeUseBotQueues"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={touched.timeUseBotQueues && Boolean(errors.timeUseBotQueues)}
                        helperText={touched.timeUseBotQueues && errors.timeUseBotQueues}
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    {/* Expires Ticket */}
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.expiresTicket")}
                        fullWidth
                        name="expiresTicket"
                        required={values.timeInactiveMessage > 0}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                        helperText={touched.expiresTicket && errors.expiresTicket}
                        className={classes.textField}
                      />
                    </Grid>

                    {/* When Expires Ticket */}
                    <Grid item xs={12} md={6}>
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        size="small"
                        className={classes.formControl}
                      >
                        <InputLabel id="whenExpiresTicket-selection-label">
                          {i18n.t("whatsappModal.form.whenExpiresTicket")}
                        </InputLabel>
                        <Field
                          as={Select}
                          label={i18n.t("whatsappModal.form.whenExpiresTicket")}
                          labelId="whenExpiresTicket-selection-label"
                          id="whenExpiresTicket"
                          name="whenExpiresTicket"
                          className={classes.select}
                        >
                          <MenuItem value={"0"}>
                            {i18n.t("whatsappModal.form.closeLastMessageOptions1")}
                          </MenuItem>
                          <MenuItem value={"1"}>
                            {i18n.t("whatsappModal.form.closeLastMessageOptions2")}
                          </MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Expires Inactive Message */}
                  <Box>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.expiresInactiveMessage")}
                      multiline
                      rows={3}
                      fullWidth
                      name="expiresInactiveMessage"
                      error={touched.expiresInactiveMessage && Boolean(errors.expiresInactiveMessage)}
                      helperText={touched.expiresInactiveMessage && errors.expiresInactiveMessage}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      className={classes.textField}
                    />
                  </Box>

                  {/* Time Inactive Message */}
                  <Box>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.timeInactiveMessage")}
                      fullWidth
                      name="timeInactiveMessage"
                      variant="outlined"
                      margin="dense"
                      size="small"
                      error={touched.timeInactiveMessage && Boolean(errors.timeInactiveMessage)}
                      helperText={touched.timeInactiveMessage && errors.timeInactiveMessage}
                      className={classes.textField}
                    />
                  </Box>

                  {/* Inactive Message */}
                  <Box>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.inactiveMessage")}
                      multiline
                      rows={3}
                      fullWidth
                      name="inactiveMessage"
                      error={touched.inactiveMessage && Boolean(errors.inactiveMessage)}
                      helperText={touched.inactiveMessage && errors.inactiveMessage}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      className={classes.textField}
                    />
                  </Box>
                </DialogContent>
              </TabPanelContainer>

              {/* NPS Tab */}
              <TabPanelContainer value={tab} name={"nps"}>
                <DialogContent className={classes.container}>
                  {/* Rating Message */}
                  <Box>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.ratingMessage")}
                      multiline
                      rows={3}
                      fullWidth
                      name="ratingMessage"
                      error={touched.ratingMessage && Boolean(errors.ratingMessage)}
                      helperText={touched.ratingMessage && errors.ratingMessage}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      className={classes.textField}
                    />
                  </Box>
                  <Grid container spacing={1}>
                    {/* Max Uses of Bot Queues NPS */}
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.maxUseBotQueuesNPS")}
                        fullWidth
                        name="maxUseBotQueuesNPS"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={touched.maxUseBotQueuesNPS && Boolean(errors.maxUseBotQueuesNPS)}
                        helperText={touched.maxUseBotQueuesNPS && errors.maxUseBotQueuesNPS}
                        className={classes.textField}
                      />
                    </Grid>

                    {/* Expires Ticket NPS */}
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.expiresTicketNPS")}
                        fullWidth
                        name="expiresTicketNPS"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={touched.expiresTicketNPS && Boolean(errors.expiresTicketNPS)}
                        helperText={touched.expiresTicketNPS && errors.expiresTicketNPS}
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </TabPanelContainer>

              {/* Schedules Tab */}
              {tab === "schedules" && schedulesEnabled && (
                <TabPanelContainer value={tab} name={"schedules"}>
                  <DialogContent className={classes.container}>
                    <SchedulesForm
                      loading={false}
                      onSubmit={handleSaveSchedules}
                      initialValues={schedules}
                      labelSaveButton={i18n.t("whatsappModal.buttons.okAdd")}
                    />
                  </DialogContent>
                </TabPanelContainer>
              )}

            </Box>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
                style={{ borderRadius: 10 }}
              >
                {i18n.t("whatsappModal.buttons.cancel")}
              </Button>
              <div className={classes.btnWrapper}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  style={{ borderRadius: 10 }}
                >
                  {whatsAppId ? i18n.t("whatsappModal.buttons.okEdit") : i18n.t("whatsappModal.buttons.okAdd")}
                </Button>
                {isSubmitting && isSubmitting && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </div>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(WhatsAppModal);

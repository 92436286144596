import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import { Formik, FastField, FieldArray } from "formik";
import { isArray } from "lodash";
import NumberFormat from "react-number-format";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  textfield: {
    width: "100%",
    fontSize: "0.875em",
  },
  notchedOutline: {
    borderRadius: "10px",
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
  button: {
    borderRadius: "10px",
  },
  dayHeader: {
    fontWeight: 600,
    minWidth: 110,
  },
  dayContainer: {
    padding: theme.spacing(0.5, 0),
  },
}));

function SchedulesForm(props) {
  const { initialValues, onSubmit, loading, labelSaveButton } = props;
  const classes = useStyles();

  const [schedules, setSchedules] = useState([
    {
      weekday: i18n.t("queueModal.serviceHours.monday"),
      weekdayEn: "monday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
    {
      weekday: i18n.t("queueModal.serviceHours.tuesday"),
      weekdayEn: "tuesday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
    {
      weekday: i18n.t("queueModal.serviceHours.wednesday"),
      weekdayEn: "wednesday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
    {
      weekday: i18n.t("queueModal.serviceHours.thursday"),
      weekdayEn: "thursday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
    {
      weekday: i18n.t("queueModal.serviceHours.friday"),
      weekdayEn: "friday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
    {
      weekday: i18n.t("queueModal.serviceHours.saturday"),
      weekdayEn: "saturday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
    {
      weekday: i18n.t("queueModal.serviceHours.sunday"),
      weekdayEn: "sunday",
      startTimeA: "",
      endTimeA: "",
      startTimeB: "",
      endTimeB: "",
    },
  ]);

  useEffect(() => {
    if (isArray(initialValues) && initialValues.length > 0) {
      setSchedules(initialValues);
    }
  }, [initialValues]);

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ schedules }}
      onSubmit={({ schedules }) =>
        setTimeout(() => {
          handleSubmit(schedules);
        }, 500)
      }
    >
      {({ values, handleSubmit }) => (
        <div className={classes.fullWidth}>
          <FieldArray
            name="schedules"
            render={(arrayHelpers) => (
              <Box>
                {values.schedules.map((item, index) => (
                  <Box key={index} className={classes.dayContainer}>
                    <Box display="flex" alignItems="center" width="100%">
                      <Box flexShrink={0} mr={2}>
                        <Typography
                          variant="body1"
                          className={classes.dayHeader}
                        >
                          {item.weekday}
                        </Typography>
                      </Box>
                      <Box display="flex" flexGrow={1}>
                        <Box flexGrow={1} minWidth={80} mx={0.5}>
                          <FastField
                            name={`schedules[${index}].startTimeA`}
                          >
                            {({ field }) => (
                              <NumberFormat
                                {...field}
                                variant="outlined"
                                margin="dense"
                                customInput={TextField}
                                format="##:##"
                                className={classes.fullWidth}
                                label={i18n.t(
                                  "queueModal.serviceHours.startTimeA"
                                )}
                                InputProps={{
                                  classes: {
                                    notchedOutline:
                                      classes.notchedOutline,
                                  },
                                }}
                              />
                            )}
                          </FastField>
                        </Box>
                        <Box flexGrow={1} minWidth={80} mx={0.5}>
                          <FastField
                            name={`schedules[${index}].endTimeA`}
                          >
                            {({ field }) => (
                              <NumberFormat
                                {...field}
                                variant="outlined"
                                margin="dense"
                                customInput={TextField}
                                format="##:##"
                                className={classes.fullWidth}
                                label={i18n.t(
                                  "queueModal.serviceHours.endTimeA"
                                )}
                                InputProps={{
                                  classes: {
                                    notchedOutline:
                                      classes.notchedOutline,
                                  },
                                }}
                              />
                            )}
                          </FastField>
                        </Box>
                        <Box flexGrow={1} minWidth={80} mx={0.5}>
                          <FastField
                            name={`schedules[${index}].startTimeB`}
                          >
                            {({ field }) => (
                              <NumberFormat
                                {...field}
                                variant="outlined"
                                margin="dense"
                                customInput={TextField}
                                format="##:##"
                                className={classes.fullWidth}
                                label={i18n.t(
                                  "queueModal.serviceHours.startTimeB"
                                )}
                                InputProps={{
                                  classes: {
                                    notchedOutline:
                                      classes.notchedOutline,
                                  },
                                }}
                              />
                            )}
                          </FastField>
                        </Box>
                        <Box flexGrow={1} minWidth={80} mx={0.5}>
                          <FastField
                            name={`schedules[${index}].endTimeB`}
                          >
                            {({ field }) => (
                              <NumberFormat
                                {...field}
                                variant="outlined"
                                margin="dense"
                                customInput={TextField}
                                format="##:##"
                                className={classes.fullWidth}
                                label={i18n.t(
                                  "queueModal.serviceHours.endTimeB"
                                )}
                                InputProps={{
                                  classes: {
                                    notchedOutline:
                                      classes.notchedOutline,
                                  },
                                }}
                              />
                            )}
                          </FastField>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          ></FieldArray>
          <div className={classes.buttonContainer}>
            <ButtonWithSpinner
              loading={loading}
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              className={classes.button}
            >
              {labelSaveButton ?? i18n.t("whatsappModal.buttons.okEdit")}
            </ButtonWithSpinner>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default SchedulesForm;

import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize, head } from "lodash";
import {
  DeleteOutline,
  AttachFile,
  Facebook,
  Instagram,
  WhatsApp,
} from "@material-ui/icons";
import ConfirmationModal from "../ConfirmationModal";
import MessageVariablesPicker from "../MessageVariablesPicker";
import useQueues from "../../hooks/useQueues";
import UserStatusIcon from "../UserModal/statusIcon";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 10,
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
  select: {
    "& .MuiSelect-outlined": {
      borderRadius: 10,
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    minWidth: "50%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`schedule-tabpanel-${index}`}
      aria-labelledby={`schedule-tab-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  contactId,
  cleanContact,
  reload,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const isMounted = useRef(true);
  const { companyId } = user;

  const initialScheduleState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
    openTicket: "enabled",
    ticketUserId: "",
    queueId: "",
    statusTicket: "closed",
    intervalo: 1,
    valorIntervalo: 0,
    enviarQuantasVezes: 1,
    tipoDias: 4,
    assinar: false,
  };

  const initialContactState = {
    id: "",
    name: "",
    channel: "",
  };

  const [schedule, setSchedule] = useState(initialScheduleState);
  const [currentContact, setCurrentContact] = useState(initialContactState);
  const [contacts, setContacts] = useState([initialContactState]);
  const [intervalo, setIntervalo] = useState(1);
  const [tipoDias, setTipoDias] = useState(4);
  const [attachment, setAttachment] = useState(null);
  const attachmentFileRef = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const messageInputRef = useRef();
  const [channelFilter, setChannelFilter] = useState("whatsapp");
  const [whatsapps, setWhatsapps] = useState([]);
  const [selectedWhatsappId, setSelectedWhatsappId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueueId, setSelectedQueueId] = useState(null);
  const { findAll: findAllQueues } = useQueues();
  const [userOptions, setUserOptions] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const loadQueues = async () => {
        const list = await findAllQueues();
        setAllQueues(list);
        setQueues(list);
      };
      loadQueues();
    }
  }, [findAllQueues]);

  useEffect(() => {
    if (searchParam.length < 3) {
      setLoading(false);
      setSelectedQueueId("");
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/");
          setUserOptions(data.users);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toastError(error);
        }
      };

      fetchUsers();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  useEffect(() => {
    api
      .get(`/whatsapp/filter`, { params: { session: 0, channel: channelFilter } })
      .then(({ data }) => {
        const mappedWhatsapps = data.map((whatsapp) => ({
          ...whatsapp,
          selected: false,
        }));

        setWhatsapps(mappedWhatsapps);
        if (mappedWhatsapps.length && mappedWhatsapps.length === 1) {
          setSelectedWhatsappId(mappedWhatsapps[0].id);
        }
      });
  }, [currentContact, channelFilter]);

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    if (open) {
      const loadData = async () => {
        try {
          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId },
          });
          const customContactList = contactList.map((c) => ({
            id: c.id,
            name: c.name,
            channel: c.channel,
          }));
          if (isArray(customContactList)) {
            setContacts([{ id: "", name: "", channel: "" }, ...customContactList]);
          }
          if (contactId) {
            setSchedule((prevState) => ({
              ...prevState,
              contactId,
            }));
          }
          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);
          setSchedule((prevState) => ({
            ...prevState,
            ...data,
            sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
          }));

          if (data.whatsapp) {
            setSelectedWhatsappId(data.whatsapp.id);
          }

          if (data.ticketUser) {
            setSelectedUser(data.ticketUser);
          }

          if (data.queueId) {
            setSelectedQueueId(data.queueId);
          }

          if (data.intervalo) {
            setIntervalo(data.intervalo);
          }

          if (data.tipoDias) {
            setTipoDias(data.tipoDias);
          }

          setCurrentContact(data.contact);
        } catch (error) {
          toastError(error);
        }
      };
      loadData();
    }
  }, [scheduleId, contactId, open, companyId]);

  const filterOptions = createFilterOptions({
    trim: true,
  });

  const handleClose = () => {
    setAttachment(null);
    setSchedule(initialScheduleState);
    setCurrentContact(initialContactState);
    onClose();
  };

  const handleAttachmentFile = (event) => {
    const file = head(event.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const IconChannel = (channel) => {
    switch (channel) {
      case "facebook":
        return <Facebook style={{ color: "#3b5998", verticalAlign: "middle" }} />;
      case "instagram":
        return <Instagram style={{ color: "#e1306c", verticalAlign: "middle" }} />;
      case "whatsapp":
        return <WhatsApp style={{ color: "#25d366", verticalAlign: "middle" }} />;
      default:
        return null;
    }
  };

  const renderOption = (option) => {
    if (option.name) {
      return (
        <>
          {IconChannel(option.channel)}
          <Typography
            component="span"
            style={{
              fontSize: 14,
              marginLeft: "10px",
              display: "inline-flex",
              alignItems: "center",
              lineHeight: "2",
            }}
          >
            {option.name}
          </Typography>
        </>
      );
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const handleSaveSchedule = async (values) => {
    const scheduleData = {
      ...values,
      userId: user.id,
      whatsappId: selectedWhatsappId,
      ticketUserId: selectedUser?.id || null,
      queueId: selectedQueueId || null,
      intervalo: intervalo || 1,
      tipoDias: tipoDias || 4,
    };

    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${scheduleId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload === "function") {
        reload();
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact();
          history.push("/schedules");
        }
      }
    } catch (error) {
      toastError(error);
    }
    handleClose();
  };

  const handleClickMsgVar = async (msgVar, setValueFunc) => {
    const inputElement = messageInputRef.current;
    const firstHalfText = inputElement.value.substring(0, inputElement.selectionStart);
    const secondHalfText = inputElement.value.substring(inputElement.selectionEnd);
    const newCursorPosition = inputElement.selectionStart + msgVar.length;

    setValueFunc("body", `${firstHalfText}${msgVar}${secondHalfText}`);

    await new Promise((resolve) => setTimeout(resolve, 100));
    messageInputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFileRef.current.value = null;
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`);
      setSchedule((prev) => ({
        ...prev,
        mediaPath: null,
      }));
      toast.success(i18n.t("scheduleModal.toasts.deleted"));
      if (typeof reload === "function") {
        reload();
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title">
          {schedule.status === "ERRO"
            ? "Erro de Envio"
            : `Agendamento ${capitalize(schedule.status)}`}
        </DialogTitle>
        <div style={{ display: "none" }}>
          <input
            type="file"
            accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.gif"
            ref={attachmentFileRef}
            onChange={handleAttachmentFile}
          />
        </div>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                className={classes.tabs}
              >
                <Tab label="Mensagem" className={classes.tab} />
                <Tab label="Configurações" className={classes.tab} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.formControl}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        value={currentContact}
                        options={contacts}
                        onChange={(event, contact) => {
                          const contactId = contact ? contact.id : "";
                          setSchedule({ ...schedule, contactId });
                          setCurrentContact(contact ? contact : initialContactState);
                          setChannelFilter(contact ? contact.channel : "whatsapp");
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={renderOption}
                        getOptionSelected={(option, value) => {
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Contato"
                            className={classes.autocomplete}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      rows={9}
                      multiline={true}
                      label={i18n.t("scheduleModal.form.body")}
                      name="body"
                      inputRef={messageInputRef}
                      error={touched.body && Boolean(errors.body)}
                      helperText={touched.body && errors.body}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      size="small"
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MessageVariablesPicker
                      disabled={isSubmitting}
                      onClick={(value) => handleClickMsgVar(value, setFieldValue)}
                    />
                  </Grid>
                  {(schedule.mediaPath || attachment) && (
                    <Grid item xs={12}>
                      <Button startIcon={<AttachFile />}>
                        {attachment ? attachment.name : schedule.mediaName}
                      </Button>
                      <IconButton onClick={() => setConfirmationOpen(true)} color="secondary">
                        <DeleteOutline color="secondary" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={`${classes.formControl} ${classes.select}`}
                    >
                      <InputLabel id="whatsapp-selection-label">
                        {i18n.t("campaigns.dialog.form.whatsapp")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("campaigns.dialog.form.whatsapp")}
                        placeholder={i18n.t("campaigns.dialog.form.whatsapp")}
                        labelId="whatsapp-selection-label"
                        id="whatsappIds"
                        name="whatsappIds"
                        required
                        error={touched.whatsappId && Boolean(errors.whatsappId)}
                        value={selectedWhatsappId}
                        onChange={(event) => setSelectedWhatsappId(event.target.value)}
                        className={classes.select}
                      >
                        {whatsapps &&
                          whatsapps.map((whatsapp) => (
                            <MenuItem key={whatsapp.id} value={whatsapp.id}>
                              {whatsapp.name}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={`${classes.formControl} ${classes.select}`}
                    >
                      <InputLabel id="openTicket-selection-label">
                        {i18n.t("campaigns.dialog.form.openTicket")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("campaigns.dialog.form.openTicket")}
                        placeholder={i18n.t("campaigns.dialog.form.openTicket")}
                        labelId="openTicket-selection-label"
                        id="openTicket"
                        name="openTicket"
                        error={touched.openTicket && Boolean(errors.openTicket)}
                        className={classes.select}
                      >
                        <MenuItem value={"enabled"}>
                          {i18n.t("campaigns.dialog.form.enabledOpenTicket")}
                        </MenuItem>
                        <MenuItem value={"disabled"}>
                          {i18n.t("campaigns.dialog.form.disabledOpenTicket")}
                        </MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      variant="outlined"
                      margin="dense"
                      className={classes.formControl}
                      getOptionLabel={(option) => `${option.name}`}
                      value={selectedUser}
                      size="small"
                      onChange={(e, newValue) => {
                        setSelectedUser(newValue);
                        if (newValue != null && Array.isArray(newValue.queues)) {
                          if (newValue.queues.length === 1) {
                            setSelectedQueueId(newValue.queues[0].id);
                          }
                          setQueues(newValue.queues);
                        } else {
                          setQueues(allQueues);
                          setSelectedQueueId("");
                        }
                      }}
                      options={userOptions}
                      filterOptions={filterOptions}
                      freeSolo
                      fullWidth
                      disabled={values.openTicket === "disabled"}
                      autoHighlight
                      noOptionsText={i18n.t("transferTicketModal.noOptions")}
                      loading={loading}
                      renderOption={option => (<span> <UserStatusIcon user={option} /> {option.name}</span>)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t("transferTicketModal.fieldLabel")}
                          variant="outlined"
                          size="small"
                          onChange={(e) => setSearchParam(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth size="small" className={`${classes.formControl} ${classes.select}`}>
                      <InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
                      <Select
                        value={selectedQueueId}
                        onChange={(e) => setSelectedQueueId(e.target.value)}
                        label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
                        disabled={values.openTicket === "disabled"}
                      >
                        {queues.map((queue) => (
                          <MenuItem key={queue.id} value={queue.id}>
                            {queue.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth size="small" className={`${classes.formControl} ${classes.select}`}>
                      <InputLabel id="statusTicket-selection-label">
                        {i18n.t("campaigns.dialog.form.statusTicket")}
                      </InputLabel>
                      <Field
                        as={Select}
                        disabled={values.openTicket === "disabled"}
                        label={i18n.t("campaigns.dialog.form.statusTicket")}
                        placeholder={i18n.t("campaigns.dialog.form.statusTicket")}
                        labelId="statusTicket-selection-label"
                        id="statusTicket"
                        name="statusTicket"
                        error={touched.statusTicket && Boolean(errors.statusTicket)}
                      >
                        <MenuItem value={"closed"}>
                          {i18n.t("campaigns.dialog.form.closedTicketStatus")}
                        </MenuItem>
                        <MenuItem value={"open"}>
                          {i18n.t("campaigns.dialog.form.openTicketStatus")}
                        </MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("scheduleModal.form.sendAt")}
                      type="datetime-local"
                      name="sendAt"
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="assinar"
                          checked={values.assinar}
                          disabled={values.openTicket === "disabled"}
                        />
                      }
                      label={i18n.t("scheduleModal.form.assinar")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Recorrência</Typography>
                    <Typography variant="body2">
                      Você pode escolher enviar a mensagem de forma recorrente e escolher o
                      intervalo. Caso seja uma mensagem a ser enviada uma única vez, não altere nada
                      nesta seção.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth size="small" className={`${classes.formControl} ${classes.select}`}>
                      <InputLabel id="intervalo-label">Intervalo</InputLabel>
                      <Select
                        labelId="intervalo-label"
                        id="intervalo"
                        value={intervalo}
                        onChange={(e) => setIntervalo(e.target.value || 1)}
                        label="Intervalo"
                      >
                        <MenuItem value={1}>Dias</MenuItem>
                        <MenuItem value={2}>Semanas</MenuItem>
                        <MenuItem value={3}>Meses</MenuItem>
                        <MenuItem value={4}>Minutos</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      label="Valor do Intervalo"
                      name="valorIntervalo"
                      size="small"
                      error={touched.valorIntervalo && Boolean(errors.valorIntervalo)}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      label="Enviar quantas vezes"
                      name="enviarQuantasVezes"
                      size="small"
                      error={touched.enviarQuantasVezes && Boolean(errors.enviarQuantasVezes)}
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small" fullWidth variant="outlined" className={`${classes.formControl} ${classes.select}`}>
                      <InputLabel id="tipoDias-label">Enviar quantas vezes</InputLabel>
                      <Select
                        labelId="tipoDias-label"
                        id="tipoDias"
                        value={tipoDias}
                        onChange={(e) => setTipoDias(e.target.value || 4)}
                        label="Enviar quantas vezes"
                      >
                        <MenuItem value={4}>Enviar normalmente em dias não úteis</MenuItem>
                        <MenuItem value={5}>Enviar um dia útil antes</MenuItem>
                        <MenuItem value={6}>Enviar um dia útil depois</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <DialogActions>
                {(schedule.sentAt === null || schedule.sentAt === "") &&
                  !attachment &&
                  !schedule.attachment && (
                    <Button
                      color="primary"
                      onClick={() => attachmentFileRef.current.click()}
                      disabled={isSubmitting}
                      variant="outlined"
                      style={{ borderRadius: 10 }}
                    >
                      {i18n.t("quickMessages.buttons.attach")}
                    </Button>
                  )}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                  style={{ borderRadius: 10 }}
                >
                  {i18n.t("scheduleModal.buttons.cancel")}
                </Button>
                {(schedule.sentAt === null || schedule.sentAt === "") && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    style={{ borderRadius: 10 }}
                  >
                    {scheduleId
                      ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                      : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ScheduleModal;

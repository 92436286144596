import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  withStyles,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "10px",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: "10px 10px 0 0",
  },
  dialogContent: {
    overflowY: "hidden",
  },
  formControl: {
    margin: theme.spacing(1, 0),
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  select: {
    borderRadius: "10px",
  },
  dialogActions: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: "0 0 10px 10px",
  },
  button: {
    borderRadius: "10px",
    textTransform: "none",
  },
  headerTypography: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));

const CustomSelect = withStyles((theme) => ({
  outlined: {
    "& fieldset": {
      borderRadius: "10px",
    },
  },
}))(Select);

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const initialSettings = {
  messageInterval: 20,
  longerIntervalAfter: 20,
  greaterInterval: 60,
  variables: [],
  sabado: "false",
  domingo: "false",
  startHour: "09:00",
  endHour: "18:00",
};

const CampaignsConfigModal = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    api.get("/campaign-settings").then(({ data }) => {
      const settingsList = [];
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
          settingsList.push([item.key, item.value]);
        });
        setSettings(Object.fromEntries(settingsList));
      }
    });
  }, []);

  const handleOnChangeSettings = (e) => {
    const changedProp = {};
    changedProp[e.target.name] = e.target.value;
    setSettings((prev) => ({ ...prev, ...changedProp }));
  };

  const saveSettings = async () => {
    try {
      await api.post("/campaign-settings", { settings });
      toast.success("Configurações salvas");
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{
        style: { marginBottom: "20%" },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.t("campaignsConfig.title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className={classes.headerTypography}>
          Intervalos
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth size="small" className={classes.formControl}>
              <InputLabel id="messageInterval-label">
                {i18n.t("campaigns.settings.randomInterval")}
              </InputLabel>
              <CustomSelect
                name="messageInterval"
                id="messageInterval"
                labelId="messageInterval-label"
                label={i18n.t("campaigns.settings.randomInterval")}
                value={settings.messageInterval}
                onChange={handleOnChangeSettings}
                MenuProps={menuProps}
                size="small"
              >
                <MenuItem value={0}>{i18n.t("campaigns.settings.noBreak")}</MenuItem>
                <MenuItem value={5}>5 segundos</MenuItem>
                <MenuItem value={10}>10 segundos</MenuItem>
                <MenuItem value={15}>15 segundos</MenuItem>
                <MenuItem value={20}>20 segundos</MenuItem>
                <MenuItem value={30}>30 segundos</MenuItem>
                <MenuItem value={40}>40 segundos</MenuItem>
                <MenuItem value={60}>60 segundos</MenuItem>
                <MenuItem value={80}>80 segundos</MenuItem>
                <MenuItem value={100}>100 segundos</MenuItem>
                <MenuItem value={120}>120 segundos</MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth size="small" className={classes.formControl}>
              <InputLabel id="longerIntervalAfter-label">
                {i18n.t("campaigns.settings.intervalGapAfter")}
              </InputLabel>
              <CustomSelect
                name="longerIntervalAfter"
                id="longerIntervalAfter"
                labelId="longerIntervalAfter-label"
                label={i18n.t("campaigns.settings.intervalGapAfter")}
                value={settings.longerIntervalAfter}
                onChange={handleOnChangeSettings}
                MenuProps={menuProps}
                size="small"
              >
                <MenuItem value={0}>{i18n.t("campaigns.settings.undefined")}</MenuItem>
                <MenuItem value={5}>
                  5 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={10}>
                  10 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={15}>
                  15 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={20}>
                  20 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={30}>
                  30 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={40}>
                  40 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={50}>
                  50 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
                <MenuItem value={60}>
                  60 {i18n.t("campaigns.settings.messages")}
                </MenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth size="small" className={classes.formControl}>
              <InputLabel id="greaterInterval-label">
                {i18n.t("campaigns.settings.laggerTriggerRange")}
              </InputLabel>
              <CustomSelect
                name="greaterInterval"
                id="greaterInterval"
                labelId="greaterInterval-label"
                label={i18n.t("campaigns.settings.laggerTriggerRange")}
                value={settings.greaterInterval}
                onChange={handleOnChangeSettings}
                MenuProps={menuProps}
                size="small"
              >
                <MenuItem value={0}>{i18n.t("campaigns.settings.noBreak")}</MenuItem>
                {[...Array(9)].map((_, index) => {
                  const value = (index + 2) * 20;
                  return (
                    <MenuItem key={value} value={value}>
                      {value} segundos
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: theme.palette.error.main, color: "#FFF" }}
        >
          {i18n.t("campaigns.settings.close")}
        </Button>
        <Button
          onClick={saveSettings}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          {i18n.t("campaigns.settings.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignsConfigModal;

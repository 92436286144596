import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';

export default function TicketMessagesDialog({ open, handleClose, ticketId }) {
  const [exportedToPDF, setExportedToPDF] = useState(false);

  // Função para criar o PDF a partir do HTML com limite de páginas
  const createPdfFromHtml = async (elementId, pdfOptions) => {
    const element = document.getElementById(elementId);
    
    if (!element) {
      throw new Error(`Elemento com id "${elementId}" não encontrado.`);
    }
    
    // Ajusta o estilo do elemento para garantir que todo o conteúdo seja capturado
    const originalStyle = {
      overflow: element.style.overflow,
      width: element.style.width,
      height: element.style.height,
      position: element.style.position
    };
    
    element.style.overflow = 'visible';
    element.style.position = 'absolute';
    element.style.width = '100%';
    element.style.height = 'auto';
    
    const canvas = await html2canvas(element, { scale: pdfOptions.html2canvas.scale });
    
    element.style.overflow = originalStyle.overflow;
    element.style.position = originalStyle.position;
    element.style.width = originalStyle.width;
    element.style.height = originalStyle.height;
    
    const imgData = canvas.toDataURL(pdfOptions.image.type, pdfOptions.image.quality);
    
    const pdf = new jsPDF(
      pdfOptions.jsPDF.orientation,
      pdfOptions.jsPDF.unit,
      pdfOptions.jsPDF.format
    );
    
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pdfWidth - 2 * pdfOptions.margin;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    
    let position = pdfOptions.margin;
    pdf.addImage(imgData, 'JPEG', pdfOptions.margin, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    const pdfBlobs = [pdf.output('blob')];
    
    // Limite máximo de páginas
    const maxPages = 100;
    let pagesCount = 1;
    
    while (heightLeft > 0 && pagesCount < maxPages) {
      pdf.addPage();
      position -= pdfHeight;
      pdf.addImage(imgData, 'JPEG', pdfOptions.margin, position + pdfOptions.margin, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
      pdfBlobs.push(pdf.output('blob'));
      pagesCount++;
    }

    // Se o conteúdo exceder o limite de páginas, adicione uma mensagem no final
    if (pagesCount >= maxPages) {
      const finalMessage = 'O documento foi dividido em várias partes. Verifique as partes seguintes.';
      pdf.addPage();
      pdf.setFontSize(12);
      pdf.text(finalMessage, pdfOptions.margin, pdfOptions.margin);
      pdfBlobs.push(pdf.output('blob'));
    }
  
    return pdfBlobs;
  };

  // Função para combinar PDFs
  const mergePdfs = async (pdfBlobs) => {
    const mergedPdf = await PDFDocument.create();
    
    for (const pdfBlob of pdfBlobs) {
      const pdfBytes = await pdfBlob.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    
    return mergedPdf.save();
  };

  // Função para exportar e gerar o PDF
  const handleExportToPDF = async () => {
    try {
      const pdfOptions = {
        margin: 10,
        filename: `relatório_atendimento_${ticketId}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      
      const pdfBlobs = await createPdfFromHtml('messagesList', pdfOptions);
      const combinedPdfBytes = await mergePdfs(pdfBlobs);
      
      const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = pdfOptions.filename;
      link.click();
      URL.revokeObjectURL(url);

      setExportedToPDF(true);
      handleClose();
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
    }
  };

  // Função para exportar e fechar o diálogo
  const handleExportAndClose = () => {
    if (!exportedToPDF) {
      handleExportToPDF();
    }
  };

  // Efeito para exportar e fechar o diálogo quando o estado `open` mudar
  useEffect(() => {
    if (open) {
      handleExportAndClose();
    }
  }, [open, ticketId]);

  return (
    <Dialog maxWidth="md" onClose={handleClose} open={open}>
      <DialogActions>
        <Button onClick={handleExportToPDF} color="primary">
          Exportar para PDF
        </Button>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

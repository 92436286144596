import React from "react";
import { Avatar, Card, CardContent, Typography, Box, Tooltip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    fontSize: "0.875rem",
    maxWidth: 300,
    height: "100%",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "10px",
    boxShadow: theme.shadows[2],
    transition: "transform 0.2s",
  },
  avatar: {
    backgroundColor: theme.palette.background.default,
    color: grey[700],
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  value: {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: theme.palette.text.secondary,
    minHeight: "1.5em",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export default function CardCounter(props) {
  const { icon, title, value, loading } = props;
  const classes = useStyles();

  const shouldTruncate = value && value.length > 30;
  const displayValue = value
    ? shouldTruncate
      ? `${value.substring(0, 30)}...`
      : value
    : "\u00A0";
  const tooltipTitle = shouldTruncate ? value : "";

  return !loading ? (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Avatar className={classes.avatar}>{icon}</Avatar>
        <Typography className={classes.title} gutterBottom>
          {title}
        </Typography>
        {tooltipTitle ? (
          <CustomTooltip title={tooltipTitle} arrow>
            <Typography className={classes.value}>{displayValue}</Typography>
          </CustomTooltip>
        ) : (
          <Typography className={classes.value}>{displayValue}</Typography>
        )}
      </CardContent>
    </Card>
  ) : (
    <Skeleton variant="rect" height={150} />
  );
}
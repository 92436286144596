import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable } from 'react-beautiful-dnd';
import KanbanCard from './KanbanCard';

const useStyles = makeStyles(theme => ({
  column: props => ({
    backgroundColor: props.color || '#ebecf0',
    borderRadius: 8,
    minWidth: 272,
    maxWidth: 272,
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  }),
  columnTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '1rem',
    color: "#D6D6D6",
  },
  cardList: {
    flexGrow: 1,
    overflowY: 'auto',
    ...theme.scrollbarStyles,
    maxHeight: 'calc(100vh - 200px)',
  },
}));

const KanbanColumn = ({ id, title, tickets, color }) => {
  const classes = useStyles({ color });

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          className={classes.column}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className={classes.columnTitle}>{title}</div>
          <div className={classes.cardList}>
            {tickets.map((ticket, index) => (
              <KanbanCard key={ticket.id} ticket={ticket} index={index} />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default KanbanColumn;

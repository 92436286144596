import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import MomentsUser from "../../components/MomentsUser";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import ForbiddenPage from "../../components/ForbiddenPage";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "94vh",
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "auto",
    ...theme.scrollbarStyles,
    borderRadius: "10px",
  },
  titleContainer: {
    margin: theme.spacing(1, 0),
  },
}));

const ChatMoments = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  if (user.profile === "user" && user.allowRealTime === "disabled") {
    return <ForbiddenPage />;
  }

  return (
    <MainContainer>
      <MainHeader>
        <div className={classes.titleContainer}>
          <Title>Painel de Atendimentos</Title>
        </div>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <MomentsUser />
      </Paper>
    </MainContainer>
  );
};

export default ChatMoments;

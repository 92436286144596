import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Tooltip, Typography, Divider } from '@material-ui/core';
import { format, parseISO, isSameDay } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(0.8),
    background: '#fff',
    borderRadius: 8,
    boxShadow: '0px 10px 17px -10px rgba(0, 0, 0, 0.59)',
    marginBottom: theme.spacing(1),
    cursor: 'grab',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginRight: theme.spacing(0.5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-between',
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.mode === "light" ? theme.palette.text.primary : "#000",
  },
  ticketNumber: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: "#757575",
  },
  divider: {
    background: "#e6e6e6",
  },
  lastMessageTime: {
    fontSize: '0.8rem',
    color: "#757575",
  },
  lastMessageTimeUnread: {
    fontSize: '0.8rem',
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  cardDescription: {
    fontSize: '0.9rem',
    color: "#757575",
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  descriptionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
  },
  cardButton: {
    fontSize: '0.5rem',
    padding: '2px 6px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  connectionTag: {
    background: "#000",
    color: '#FFF',
    padding: '2px 6px',
    fontWeight: 'bold',
    borderRadius: '10px',
    fontSize: '0.5rem',
    marginLeft: 'auto',
  },
}));

const KanbanCard = ({ ticket, index }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCardClick = () => {
    history.push(`/tickets/${ticket.uuid}`);
  };

  const lastMessageTimeClass =
    Number(ticket.unreadMessages) > 0
      ? classes.lastMessageTimeUnread
      : classes.lastMessageTime;

  return (
    <Draggable draggableId={ticket.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          className={classes.card}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={classes.header}>
            <div className={classes.leftHeader}>
              <Avatar src={ticket.contact.urlPicture} className={classes.avatar} />
              <Tooltip title={ticket.contact.name}>
                <Typography className={classes.cardTitle}>
                  {ticket.contact.name?.substring(0, 10) || ' '}
                </Typography>
              </Tooltip>
            </div>
            <Typography className={classes.ticketNumber}>
              Ticket nº {ticket.id}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.descriptionRow}>
            <Tooltip title={ticket.lastMessage || ' '}>
              <Typography className={classes.cardDescription}>
                {ticket.lastMessage?.substring(0, 20) || ' '}
              </Typography>
            </Tooltip>
            <Typography className={lastMessageTimeClass}>
              {isSameDay(parseISO(ticket.updatedAt), new Date())
                ? format(parseISO(ticket.updatedAt), 'HH:mm')
                : format(parseISO(ticket.updatedAt), 'dd/MM/yyyy')}
            </Typography>
          </div>
          <div className={classes.footer}>
            <Button
              size="small"
              className={classes.cardButton}
              onClick={handleCardClick}
            >
              Ver Ticket
            </Button>
            {ticket.user && (
              <Typography className={classes.connectionTag}>
                {ticket.user.name.toUpperCase()}
              </Typography>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default KanbanCard;
